import React from "react";

const Checkbox = ({ label, name, id, value, checked, onChange, hasError, helpText }) => {
  return (
    <div className="field">
      <label htmlFor={id}>
        <input
          id={id}
          className="checkbox"
          name={name}
          value={value}
          checked={checked}
          onChange={onChange}
          type="checkbox"
        />
        {label}
      </label>
      {hasError && <div className="invalid-feedback">{helpText}</div>}
    </div>
  );
};

export default Checkbox;
