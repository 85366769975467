import React, { useEffect, useState } from "react";
import { StripeProvider } from 'react-stripe-elements';

const STRIPE_PUB_KEY = process.env.GATSBY_STRIPE_PUB_KEY;

const StripeLoader = ({ children }) => {
  const [stripe, setStripe] = useState(null);
  useEffect(() => {
    if (window.Stripe) {
      setStripe(window.Stripe(STRIPE_PUB_KEY));
    } else {
      document.querySelector('#stripejs').addEventListener('load', () => {
        setStripe(window.Stripe(STRIPE_PUB_KEY));
      });
    }
  }, []);

  return (
    <StripeProvider stripe={stripe}>
      {children}
    </StripeProvider>
  )
};

export default StripeLoader;
