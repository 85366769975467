import React from "react";

const TextInput = ({
  id,
  name,
  label,
  hint,
  placeholder,
  prepend,
  append,
  appendProps,
  prependProps,
  hasError,
  helpText,
  onChange,
  value,
  type = "text",
  size,
  className,
  style,
  autoFocus,
  disabled,
  readOnly,
  onBlur,
}) => {
  return (
    <div className="field">
      <input
        className="input-text"
        id={id}
        size={size}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        autoFocus={autoFocus}
        disabled={disabled}
        readOnly={readOnly}
        name={name}
      />
      {hasError && <div className="invalid-feedback">{helpText}</div>}
    </div>
  );
};

export default TextInput;
