import React from "react";

const Select = ({ options, value, onChange, name, hasError, helpText }) => {
  return (
    <div className="field">
      <select className="select" name={name} onChange={onChange} value={value}>
        <option value="" disabled>Please Select</option>
        {options.map((i) => (
          <option value={i.value}>{i.label}</option>
        ))}
      </select>
      {hasError && <div className="invalid-feedback">{helpText}</div>}
    </div>
  );
};

export default Select;
