import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import { navigate } from '@reach/router';
import {
  transitions,
  positions,
  useAlert,
  Provider as AlertProvider,
} from "react-alert";
import { Elements, CardElement, injectStripe } from "react-stripe-elements";
import qs from "querystring";
import Button from "../components/Button";
import { getItemDetails } from "../services/pages.svc";
import { createAccount } from "../services/account.service";
import cart from "../utils/cart";
import { API_KEY } from "../constants";
import COUNTRIES from "../constants/countries";
import { STATES } from "../constants/states";
import Spin from "../components/Spin";
import Form from "../components/form";
import Select from "../components/form/Select";
import Input from "../components/form/TextInput";
import Checkbox from "../components/form/Checkbox";
import { isChecked, validatePhone, toDbPhone } from "../utils";
import StripeLoader from "../components/StripeLoader";
import AlertTemplate from "../components/AlertTemplate";
import SEO from "../components/SEO";
import "../styles/_all.scss";

const FormItem = Form.FormItem;

const normalizePhone = (v, prev) => {
  if (v === prev) return v;
  if (v.length === 15) return prev;
  return v.replace(/^(\d{3})(\d{3})(\d)+$/, "($1) $2-$3");
};

export const query = graphql`
  query checkoutPageQuery {
    settings: sanitySiteSettings {
      title
      id
      facebook
      instagram
      twitter
      _rawOpenGraph(resolveReferences: { maxDepth: 10 })
      _rawLogo(resolveReferences: { maxDepth: 10 })
    }
  }
`;

const Checkout = ({
  data,
  location,
  stripe,
  form: { getFieldDecorator, validateFields, getFieldValue },
}) => {
  const [loading, setLoading] = useState(true);
  const [item, setItem] = useState({});
  const alert = useAlert();

  useEffect(() => {
    const query = qs.parse(location.search.substr(1));
    if (query.cart) {
      getItemDetails(query.cart).then(
        (item) => {
          setItem(item);
          setLoading(false);
        },
        (e) => {
          navigate("/pricing");
        }
      );
    } else {
      navigate("/pricing");
    }
  }, []);

  const onSubmit = () => {
    return validateFields()
      .then((values) => {
        cart.reset();
        return stripe
          .createToken()
          .then(({ token, error }) => {
            if (error) {
              throw error;
            } else {
              return token.id;
            }
          })
          .then((token) => {
            return createAccount({
              ...values,
              account_brand: "tmg",
              account_mobile: toDbPhone(values.account_mobile),
              account_phone: toDbPhone(values.account_phone),
              token: API_KEY,
              cart: item.id,
              card_token: token,
              products: item.productID,
              discount: item.discountID,
              trialDuration: item.trialDuration,
            }).then((resp) => {
              if (resp.status === "error") {
                throw new Error(resp.message);
              } else if (!resp.account_id) {
                // trying to parse error from php error
                const matches = resp.match(/"message":"([\w\s.]+)/);
                if (matches && matches[1]) {
                  throw new Error(matches[1]);
                } else {
                  throw new Error(
                    "There was an error. Please validate your input and try again."
                  );
                }
              } else {
                cart.set("product_id", item.id);
                cart.set("email", values.account_email);
                cart.set("account_id", resp.account_id);
                navigate("/offer?i=0", {
                  replace: true,
                });
              }
            });
          })
          .catch((err) => {
            alert.error(err.message);
          });
      })
      .catch((e) => {
        alert.error("Please verify your inputs");
      });
  };

  const matchEmail = (rules, value, cb) => {
    if (value) {
      const email = getFieldValue("account_email");
      if (value !== email) {
        cb(new Error("Please verify your email"));
      } else {
        cb();
      }
    } else {
      cb();
    }
  };

  const country = getFieldValue("account_country") || "US";

  const { settings } = data;
  return (
    <div>
      <SEO
        title="Start Trial"
        siteName={settings.title}
        description={settings.description}
        image={settings._rawOpenGraph.image}
      />
      <div className="checkout-layout">
        <div className="sidebar">
          <div className="sidebar-content">
            <div className="logo">
              <img src="/assets/images/tmg_logo_white.png" />
            </div>
            <div className="review">
              <div className="quote-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="42"
                  height="32"
                  viewBox="0 0 42 32"
                >
                  <path
                    fill="#FFF"
                    fillRule="evenodd"
                    d="M18.2 23.1c0 2.4-.78 4.32-2.35 5.76-1.57 1.45-3.55 2.17-5.95 2.17-2.9 0-5.27-.95-7.13-2.85C.9 26.28-.03 23.55-.03 20c0-3.8 1.1-7.55 3.3-11.23C5.44 5.1 8.52 2.17 12.5.03l2.85 4.34C13.2 5.87 11.5 7.5 10.2 9.33c-1.27 1.82-2.12 3.93-2.53 6.33.74-.33 1.6-.5 2.6-.5 2.3 0 4.22.74 5.7 2.23 1.5 1.46 2.24 3.36 2.24 5.7zm22.82 0c0 2.4-.78 4.32-2.35 5.76-1.57 1.45-3.56 2.17-5.96 2.17-2.86 0-5.24-.95-7.1-2.85-1.86-1.9-2.8-4.63-2.8-8.18 0-3.8 1.1-7.55 3.3-11.23 2.2-3.67 5.27-6.6 9.24-8.74l2.85 4.34c-2.16 1.5-3.87 3.14-5.16 4.96-1.28 1.82-2.12 3.93-2.54 6.33.74-.33 1.6-.5 2.6-.5 2.3 0 4.2.74 5.7 2.23 1.5 1.46 2.23 3.36 2.23 5.7z"
                    opacity=".28"
                  />
                </svg>
              </div>
              <blockquote cite="http://facebook">
                <p>
                  TextMyGym has significantly cut our cancellation rates, and increased our year over year revenue. Nobody likes spending money, but this is the best expense our gym will spend this year.
                </p>
                <footer>
                  <div className="review-avatar">
                    <img src="/assets/images/people/client-review-david.jpg" />
                  </div>
                  <div className="review-author">
                    <div className="review-name">Cody Hill, Thrive Gym</div>
                    <div className="review-rating">
                      <span className="review-badge">
                        5{" "}
                        <span className="star">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="9"
                            height="9"
                            viewBox="0 0 9 9"
                          >
                            <path
                              fill="#FFF"
                              fillRule="evenodd"
                              d="M4.5 0l1.4 2.82 3.1.45-2.25 2.2.53 3.1L4.5 7.1 1.72 8.56l.53-3.1L0 3.26l3.1-.44"
                            />
                          </svg>
                        </span>
                      </span>{" "}
                      from facebook
                    </div>
                  </div>
                </footer>
              </blockquote>
            </div>
          </div>
        </div>
        <Spin spinning={loading}>
          <div className="checkout">
            <div className="checkout-main">
              <div className="checkout-container">
                <h1 className="checkout-heading">
                  Start Your Free {item.trialDuration} Day Trial
                </h1>
                <p>
                  Start your free {item.trialDuration} day trial today and
                  you'll start seeing the power of Text My Gym.
                  <br /> No Contracts; Cancel at any time.
                </p>
                <Form>
                  <div className="checkout-cart">
                    <div className="checkout-cart-row">
                      <strong>{item.name} </strong>{" "}
                      <span className="pull-right-md">
                        ${item.price} / {item.freq}{" "}
                        {item.freq !== "month" &&
                          `(regularly ${item.priceOld})`}
                      </span>
                    </div>
                    <div
                      className="checkout-cart-row"
                      id="show-for-referral"
                      style={{ display: "none" }}
                    >
                      <strong>{item.name}</strong>{" "}
                      <span className="pull-right-md">FREE</span>
                    </div>
                  </div>
                  <h5>Create Your Account</h5>
                  <FormItem>
                    {getFieldDecorator("account_name", {
                      rules: [
                        {
                          required: true,
                          message: "Please enter Gym name",
                        },
                      ],
                    })(
                      <Input
                        size="lg"
                        pattern="\S.*\S"
                        placeholder="Gym Name"
                      />
                    )}
                  </FormItem>
                  <div className="d-flex form-row">
                    <FormItem>
                      {getFieldDecorator("account_first_name", {
                        rules: [
                          {
                            required: true,
                            message: "Please enter your first name",
                          },
                        ],
                      })(<Input size="lg" placeholder="First Name" />)}
                    </FormItem>
                    <FormItem>
                      {getFieldDecorator("account_last_name", {
                        rules: [
                          {
                            required: true,
                            message: "Please enter last name",
                          },
                        ],
                      })(<Input size="lg" placeholder="Last Name" />)}
                    </FormItem>
                  </div>
                  <div className="d-flex form-row">
                    <FormItem>
                      {getFieldDecorator("account_email", {
                        rules: [
                          {
                            required: true,
                            message: "Please enter email",
                            type: "email",
                          },
                        ],
                      })(<Input size="lg" placeholder="Email Address" />)}
                    </FormItem>
                    <FormItem>
                      {getFieldDecorator("account_email_confirm", {
                        rules: [
                          {
                            required: true,
                            message: "Confirm your email address",
                            type: "email",
                          },
                          {
                            validator: matchEmail,
                          },
                        ],
                      })(
                        <Input
                          size="lg"
                          placeholder="Confirm your email address"
                        />
                      )}
                    </FormItem>
                  </div>
                  <div className="d-flex form-row">
                    <FormItem>
                      {getFieldDecorator("account_mobile", {
                        normalize: normalizePhone,
                        rules: [
                          {
                            required: true,
                            message: "Please enter mobile number",
                          },
                          { validator: validatePhone },
                        ],
                      })(
                        <Input
                          size="lg"
                          type="tel"
                          placeholder="Mobile Phone"
                        />
                      )}
                    </FormItem>
                    <FormItem>
                      {getFieldDecorator("account_phone", {
                        normalize: normalizePhone,
                        rules: [
                          { required: false },
                          { validator: validatePhone },
                        ],
                      })(
                        <Input
                          size="lg"
                          type="tel"
                          placeholder="Gym Phone"
                        />
                      )}
                    </FormItem>
                  </div>
                  <div className="d-flex form-row">
                    <FormItem>
                      {getFieldDecorator("account_address1", {
                        rules: [
                          {
                            required: true,
                            message: "Please enter address line 1",
                          },
                        ],
                      })(<Input size="lg" placeholder="Address line 1" />)}
                    </FormItem>
                    <FormItem>
                      {getFieldDecorator("account_address2", {
                        rules: [
                          {
                            required: false,
                            message: "Please enter address line 2",
                          },
                        ],
                      })(<Input size="lg" placeholder="Address line 2" />)}
                    </FormItem>
                    <FormItem>
                      {getFieldDecorator("account_city", {
                        rules: [
                          {
                            required: true,
                            message: "Please enter city",
                          },
                        ],
                      })(<Input size="lg" placeholder="City" />)}
                    </FormItem>
                  </div>
                  <div className="d-flex form-row">
                    <FormItem>
                      {getFieldDecorator("account_zip", {
                        rules: [
                          {
                            required: true,
                            message: "Please Zip/Postal code",
                          },
                        ],
                      })(<Input size="lg" placeholder="Zip/Postal code" />)}
                    </FormItem>
                    <FormItem>
                      {getFieldDecorator("account_state", {
                        initialValue: "",
                        rules: [
                          {
                            required: true,
                            message: "Please enter state/provinces",
                          },
                        ],
                      })(
                        <Select
                          transformValue
                          options={STATES[country]}
                          size="lg"
                          placeholder="State/Provinces"
                        />
                      )}
                    </FormItem>
                    <FormItem>
                      {getFieldDecorator("account_country", {
                        initialValue: "US",
                        rules: [
                          {
                            required: true,
                            message: "Please select country",
                          },
                        ],
                      })(
                        <Select
                          transformValue
                          options={COUNTRIES}
                          size="lg"
                          placeholder="Country"
                        />
                      )}
                    </FormItem>
                  </div>
                  <div className="field">
                    <CardElement
                      hidePostalCode={false}
                      style={{ base: { fontSize: "16px" } }}
                    />
                  </div>
                  <FormItem>
                    {getFieldDecorator("account_tax_id")(
                      <Input
                        size="lg"
                        placeholder="Exempt Tax ID - (Optional)"
                      />
                    )}
                  </FormItem>
                  <div className="">
                    <p>
                      You will be billed <strong>$0</strong> today and then $
                      <span>
                        {item.price} per {item.freq}
                      </span>{" "}
                      after your <strong>{item.trialDuration} Day Trial</strong>
                      .
                    </p>
                  </div>
                  <div className="field membership-agreement-checkbox mb-4">
                    <FormItem>
                      {getFieldDecorator("agreement", {
                        valuePropName: "checked",
                        initialValue: false,
                        rules: [
                          {
                            validator: isChecked,
                            message: "Please accept membership agreement",
                          },
                        ],
                      })(
                        <Checkbox
                          label={
                            <span>
                              I agree to the{" "}
                              <a
                                href="https://textmygym.com/article/tou"
                                target="_blank"
                              >
                                Membership Agreement
                              </a>
                            </span>
                          }
                        />
                      )}
                    </FormItem>
                  </div>
                  <Button
                    onClick={onSubmit}
                    className="button button-blue button-cta"
                  >
                    START MY FREE TRIAL
                  </Button>
                </Form>
              </div>
            </div>
            <div className="checkout-footer">
              <div className="checkout-container">
                <h4>Our Guarantee</h4>
                <p>
                  We want to provide the best experience possible so we back our
                  product with a Full 30 Day Money-Back Guarantee.
                </p>
                <h4>No Contracts</h4>
                <p>
                  After your FREE <span>{item.trialDuration}</span> day trial
                  period, you'll automatically roll over to your {item.freq}ly
                  membership and pay just ${item.price}/{item.freq}. No
                  Contracts. <strong>Cancel at any time.</strong>
                </p>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    </div>
  );
};

const InjectedCheckout = injectStripe(Checkout);

const WrappedElement = (props) => (
  <Elements>
    <InjectedCheckout {...props} />
  </Elements>
);

const options = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: "30px",
  // you can also just use 'scale'
  transition: transitions.FADE,
};

const WithStripe = (props) => {
  return (
    <StripeLoader>
      <AlertProvider template={AlertTemplate} {...options}>
        <WrappedElement {...props} />
      </AlertProvider>
    </StripeLoader>
  );
};

export default Form.create()(WithStripe);
