export function validatePhone(rule, value, callback) {
  if (value) {
    const isValid =  true; //TODO
    if (isValid) {
      callback();
    } else if (!value.startsWith('+') && value.length === 10) {
      callback();
    } else {
      callback('Invalid phone number');
    }
  } else {
    callback();
  }
}

export function isChecked(rule, value, callback) {
  if (value) {
    callback();
  } else {
    callback(rule.message);
  }
}


export const toDbPhone = (number) => {
  if (!number) return '';
  if (!number.startsWith('+')) return number;
  // const intl =  formatPhoneNumberIntl(number, );
  // return intl.split(' ').splice(1).join('');
};
